/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import Head from 'next/head';
import { bindActionCreators } from 'redux';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import styles from 'styles/pages/404.module.scss';
import { actionPostClientLog, actionSetClientLogToNull } from 'store/actions';

const Custom404 = (props) => {
    const router = useRouter();
    const [isUsedVehicleRoute, setIsUsedVehicleRoute] = useState(false);
    const [isUppercase, setIsUppercase] = useState(false);

    useEffect(() => {
        const asPath = router.asPath;
        const regexp = new RegExp('[A-Z][A-Z]{0,2}[a-z]{0,100}');
        if (asPath.includes('occasion')) {
            setIsUsedVehicleRoute(true);
        } else if (regexp.test(router.asPath)) {
            setIsUppercase(true);
        }

        // const routes = ['/marques-voiture/', '/modeles-voiture/'];
        // check if includes uppercase characters
        /*
        const regexp = new RegExp('[A-Z][A-Z]{0,2}[a-z]{0,100}');
        if (
            (asPath.startsWith(routes[0]) || asPath.startsWith(routes[1])) &&
            regexp.test(router.asPath)
        ) {
            router.push(router.asPath.toLowerCase());
        }
        */
        props.actionPostClientLog({
            url: router.pathname,
            message: '404',
            action: router.asPath,
        });
        props.actionSetClientLogToNull();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    const Message = () => {
        if (isUsedVehicleRoute) {
            return (
                <>
                    <p>Désolé, on a suspendu les annonces occasion pour le moment.</p>
                    <p>On va introduire une toute nouvelle plateforme occasion.</p>
                    <p>À bientôt</p>
                </>
            );
        }
        if (isUppercase) {
            return (
                <>
                    <p>Ooops, la page ci-dessous ne semble pas exister..</p>
                    <p>{router.asPath}</p>
                    <p>Il faut peut-être essayer avec des lettres minuscules.</p>
                </>
            );
        }
        return (
            <>
                <p>Ooops, la page ci-dessous ne semble pas exister..</p>
                <p>{router.asPath}</p>
            </>
        );
    };

    return (
        <div className="container">
            <Head>
                <title>Page 404</title>
                <meta name="description" content="page not found" />
                <meta name="robots" content="noindex follow" />
                <meta name="googlebot" content="noindex follow" />
            </Head>
            <main className={styles.root}>
                <div className="main-title">
                    <h1>Erreur 404 - Page non trouvée</h1>
                </div>
                <div className={styles.notifierContainer}>
                    <Alert
                        style={{ maxWidth: 500, margin: '0 auto', textAlign: 'left' }}
                        variant="filled"
                        severity="error"
                    >
                        <Message />
                    </Alert>
                </div>
            </main>
        </div>
    );
};

Custom404.propTypes = {
    actionPostClientLog: PropTypes.func.isRequired,
    actionSetClientLogToNull: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            actionPostClientLog,
            actionSetClientLogToNull,
        },
        dispatch,
    );
}

export default connect(null, mapDispatchToProps)(Custom404);
